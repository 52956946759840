import React, {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Platform, RequirementItem} from 'src/interfaces/Platform.interface'
import InputFieldComponent from './InputFieldComponent'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import { handleOauthForConnectPlatformAsync } from 'src/redux/actions/authActions'
import { shopifyDetailsForOauth } from 'src/utils/constants'
import useAppToast from 'src/hooks/useAppToast'
import ConnectPlatformSelectInput from './ConnectPlatformSelectInput'
import AuthRequirementSelectInput from './AuthRequirementSelectInput'

type ConnectPlatformModalPropsType = {
  isVisible: boolean

  closeModal: () => void
  data: Platform|null
  // Add a function to handle received data from child component
  onDataReceived: (data: any, friendlyName: string) => void
  // onUpdateState: (newValue: boolean) => void
}
type dataCollectorType = {
  id: number
  name: string
  value: string
}
const ConnectPlatformModal: FC<ConnectPlatformModalPropsType> = ({
  isVisible,
  data,

  closeModal,
  onDataReceived,
}) => {
  const [inputData, setInputData] = useState({
    friendlyName: '',
    intput_type:"" // State to store the value of "Friendly Name"
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const {mainStoreData} = useAppSelector(state=>state.fetchConnectedPlatform)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const { successToast, warningToast } = useAppToast()
  const { platformSettingsLoading } = useAppSelector(state=>state.createPlatformSettings)
  const [isConnectingUsingOauth, setIsConnectingUsingOauth] = useState<boolean>(data?.isOauth ?? false )

  // const handleClick = () => {
  //   onUpdateState(true) // Call callback function to update parent state
  //   console.log('true', onUpdateState)
  // }

  const [datacollector, setDataCollector] = useState<dataCollectorType[] | []>([]) // Array to store collected data

  const handleInputChange = (id: any, name: string, value: string) => {
    setInputData({...inputData, [id]: value, [name]: value})
  }

  const handleSave = () => {
    // Collect data from inputData and update dataCollector state
    const collectedData = data?.available_platform_auth_requirement.length ? data?.available_platform_auth_requirement.map(({id, name}) => ({
      id,
      value: inputData[id],
      name: name,
    })) : []
    setDataCollector(collectedData)
    onDataReceived(collectedData, inputData.friendlyName)
    // closeModal() // Close modal after saving
    
  }

  const handleOauth = () => {
    console.log(inputData,inputData['identifier']);
    const payload = {available_platform_name: data?.name ,}
    //  let url = 'https://disconnect-v2.myshopify.com/admin/oauth/authorize?client_id=0be8fcf4e4cdc1ba5bf5087cc62287fb&scope=read_orders,write_orders,read_customers,write_customers,read_products,write_products&redirect_uri=https://react2.constacloud.com/handle-oauth-callback-restricted&state=0WZ48kIyGl9AOMDNuHq2zGxG75ErTdkLvBtFtUsEKC4'
    //  let redirectedUrl = 'http://localhost:3011/handle-oauth-callback-restricted?code=3832868a3ca99b7fb2cd329c03ae9528&hmac=cc93be7994208243552577cf9d9f07994c0458b214e4c0969c5a3dbbbb52fa4d&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvZGlzY29ubmVjdC12Mg&shop=disconnect-v2.myshopify.com&state=0WZ48kIyGl9AOMDNuHq2zGxG75ErTdkLvBtFtUsEKC4&timestamp=1715929169'
    //  const handle =  window.open(url,'_blank','popup=yes,top=100,left=300, status=yes, toolbar=yes, menubar=yes, location=yes,addressbar=yes' )
    //  console.log('handle', handle?.window.window.opener )
    //  return
    if(inputData['friendlyName']==''){
      warningToast("Channel name is required")
      return 
    }
    if(data?.name==='SHOPIFY'){
        if(inputData['identifier']==''||inputData['identifier'] === undefined ){
          warningToast("Domain is required")
          return 
        }else{
          payload['shop'] = inputData['identifier']
        }
    }

    if(data?.name==='ZohoInventory'){
      if(inputData['identifier']==''||inputData['identifier'] === undefined ){
        warningToast("Domain is required")
        return 
      }else{
        payload['auth_domain'] = inputData['identifier']
      }
    }

    if(data?.name==='TIKTOK'){
      if(inputData['country']){
        payload['country'] = inputData['country']
      }else{
        warningToast("Country is required")
        return 
      }
    }

    if(data?.name==='TEMU'){
      if(inputData['country']){
        payload['country'] = inputData['country']
      }else{
        warningToast("Country is required")
        return 
      }
    }

    if(data?.name==='AIMS360'){
      if(inputData['proxies']){
        payload['proxies'] = inputData['proxies']
      }else{
        warningToast("Country is required")
        return 
      }
    }

    if(data?.name==='WOOCOMMERCE'){
      if(inputData['identifier']){
        payload['identifier'] = inputData['identifier']
      }else{
        warningToast("Domain name is required")
        return 
      }
    }

    if(data?.name==='AMAZONSELLER'){
      if(inputData['identifier']){
        payload['identifier'] = inputData['identifier']
      }else{
        warningToast("Country is required")
        return 
      }
    }

    setLoading(true)
    dispatch(handleOauthForConnectPlatformAsync({ ...payload }))
    .then((response)=>{
      console.log('fetchedUserAccountData', fetchedUserAccountData)
      if(response.payload?.data && response.payload?.status_code===200 ){
        localStorage.setItem(shopifyDetailsForOauth, JSON.stringify({...data, friendlyName: inputData.friendlyName, isStoreCreated: mainStoreData ? true : false, userId: fetchedUserAccountData?.id, zohoAuthDomain: inputData['identifier'] ? inputData['identifier'] : ''  }) )
        setLoading(false)
        window.open(response.payload?.data?.url,"_self")
        // window.open(response.payload?.data?.url,"_blank",'height=400,width=400,top=100,left=300')
        // window.location.href = response.payload?.data?.url
      }
      if(response.payload?.data?.status_code==400 ){
          warningToast(response.payload?.data?.data?.message)
      }
      setLoading(false)  
    })
  
  }

  return (
    <>
      <Modal size='lg' backdrop='static' centered show={isVisible} onHide={()=>{
        closeModal()
        setInputData({
          friendlyName:'',
          intput_type:''
        })
      }}>
         <form onSubmit={(ev)=>{
          ev.preventDefault()
          if(data?.isOauth === false || isConnectingUsingOauth ===false){
            // alert('without oauth')
            handleSave()
          }else{
            // alert('with oauth')
            handleOauth()
          }
        }} >
        <Modal.Header>
          <Modal.Title>Connect {data?.display_name}</Modal.Title>
          <a target='_blank' href="https://commercium.tawk.help/" className='btn btn-link text-primary fs-6 fw-bold ' >Need Help ?</a>
        </Modal.Header>
       {  data?.isAllowMultiAuth ?  
        <>
          <div className='row px-10 pe-0 py-2' >
            <div className='col-md-3 col-4' >
              <label className='form-label mb-0' >Connection Type</label>
            </div>
            <div className='col-sm-9 col-12 ps-0' style={{alignContent:'space-evenly'}} >
              <div className='d-flex align-items-center'  > 
                <label htmlFor="withOauth" className=' me-2' >Secure Connection via OAuth (Recommended)<input id='withOauth' checked={isConnectingUsingOauth} className='form-check-input form-check-input-lg ms-1' name='checkOauthType' type='radio' onChange={(ev)=>{
                 if(!isConnectingUsingOauth){
                   setIsConnectingUsingOauth(ev.target.checked) 
                 }
                 
                 }} /> </label>
                <label htmlFor="withoutOauth" className=' ms-2' >Via API Keys<input id='withoutOauth' checked={!isConnectingUsingOauth} className='form-check-input ms-1' name='checkOauthType' type='radio' onChange={(ev)=>{ 
                  if(isConnectingUsingOauth){
                      setIsConnectingUsingOauth(!ev.target.checked) 
                  }
                 }} /> </label>
              </div>
            </div>
          </div>
          <div className='separator my-1' ></div>
        </> : null }
       
          <Modal.Body>
            <div className=''>
              {  (data?.available_platform_auth_requirement && data?.available_platform_auth_requirement?.length > 0) || data?.isOauth ? 
              <div className='row px-4 mb-3'> {/* Use flexbox for side-by-side */}
                <div className='col-md-3 col-6 '>  {/* Apply col-md-6 for half column width */}
                  <label className='form-label'  
                  // style={{
                  //   marginRight: '10px', // Adjust as needed
                  //   fontWeight: 'bold',
                  //   fontSize: '1.25rem', // Adjust as needed
                  // }}
                  >Channel Name</label>
                </div>
                <div className='col-sm-6 col-12 '>  {/* Apply col-md-6 for half column width */}
                  <input
                    type='text'
                    required
                    className='form-control form-control-solid'
                    placeholder='Add a Channel Name to remember this Store connection'
                    value={inputData.friendlyName}
                    onChange={(e) =>
                      handleInputChange('friendlyName', 'friendlyName', e.target.value)
                    }
                  />
                </div>
              </div> : null
              }
              {data?.available_platform_auth_requirement && data?.available_platform_auth_requirement?.length > 0 && (
                <>
                  {/* <hr /> */}
                  {/* <br /> */}
                  <div className='row '>
                    { data.isOauth === false || isConnectingUsingOauth ===false ? data?.available_platform_auth_requirement?.map((item: RequirementItem, index) =>{
                      
                      if(data.name==='TEMU'){
                        if(item.input_type=='select'){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <ConnectPlatformSelectInput
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                name={item.name}
                                inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                onDataChange={(ev) =>{
                                  handleInputChange( item.id , item.name, ev.target.value )
                                }}
                                options={[{label:'US', value:'US'},{label:'EU',value:"EU" }]}
                              />
                            </div>
                          )
                        }
                      }

                      if(data.name==='AIMS360'){
                        if(item.input_type=='select'){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <ConnectPlatformSelectInput
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                name={item.name}
                                inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                onDataChange={(ev) =>{
                                  handleInputChange( item.id , item.name, ev.target.value )
                                }}
                                options={[{label:'West US', value:'apiwest'},{label:'East US',value:"apieast" }]}
                              />
                            </div>
                          )
                        }
                      }

                      if(data.name==='FRUUGO'){
                        if(item.input_type=='select'){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <ConnectPlatformSelectInput
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                name={item.name}
                                inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                onDataChange={(ev) =>{
                                  handleInputChange( item.id , item.name, ev.target.value )
                                }}
                                options={[{label:'GBP', value:'GBP'},{label:'INR',value:"INR" },{label:"USD",value:"USD"}]}
                              />
                            </div>
                          )
                        }
                      }

                      if(data.name==='SHOPIFY'){
                        if(item.name=='identifier'){
                          return(
                          <div key={item.id} className={`mb-3 row justify-content-end`}>
                            <InputFieldComponent
                              display_name={item.display_name}
                              id={item.id}
                              input_type={item.input_type}
                              placeholder='eg. storename.myshopify.com'
                              name={item.name}
                              inputValue={inputData[item.id] || ''} // Pass the value from inputData
                              onDataChange={(inputValue) =>
                                handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                              }
                            />
                          </div>
                          )
                        }
                      }

                      if(data.name=='WOOCOMMERCE'){
                        if(item.name=="identifier"){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <InputFieldComponent
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                placeholder='Enter only domain eg. storename.mystore.com'
                                name={item.name}
                                inputValue={inputData[item.id] || ''} // Pass the value from inputData
                                onDataChange={(inputValue) =>
                                  handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                                }
                              />
                            </div>
                          )
                          
                        }
                      }

                      if(data.name=='AMAZONSELLER'){
                        if(item.input_type=='select'){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <AuthRequirementSelectInput
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                name={item.name}
                                inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                onDataChange={(ev) =>{
                                  handleInputChange( item.id , item.name, ev.target.value )
                                }}
                              />
                            </div>
                          )
                        }
                      }

                      return(
                        <div key={item.id} className={`mb-3 row justify-content-end`}>
                          <InputFieldComponent
                            display_name={item.display_name}
                            id={item.id}
                            input_type={item.input_type}
                            name={item.name}
                            inputValue={inputData[item.id] || ''} // Pass the value from inputData
                            onDataChange={(inputValue) =>
                              handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                            }
                          />
                        </div>
                        )
                      
                  }) : ['SHOPIFY','EBAY','ZohoInventory', 'TIKTOK','WOOCOMMERCE','TEMU','AMAZONSELLER' ].includes(data.name)?
                    (
                      data?.available_platform_auth_requirement?.map((item: RequirementItem, index) =>{ 
                        if(item.display_name==="Shopify Domain"){
                          return(
                            <div key={item.id} className={`mb-3 row justify-content-end`}>
                              <InputFieldComponent
                                display_name={item.display_name}
                                id={item.id}
                                input_type={item.input_type}
                                placeholder={'eg. storename.myshopify.com'}
                                name={item.name}
                                inputValue={inputData[item.id] || ''} // Pass the value from inputData
                                onDataChange={(inputValue) =>
                                  handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                                }
                              />
                            </div>
                          )
                        }else{
                          if(data.name==='ZohoInventory'){
                            return(
                              <div key={item.id} className={`mb-3 row justify-content-end`}>
                                <InputFieldComponent
                                  display_name={item.display_name}
                                  id={item.id}
                                  input_type={item.input_type}
                                  name={item.name}
                                  inputValue={inputData[item.id] || ''} // Pass the value from inputData
                                  onDataChange={(inputValue) =>
                                    handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                                  }
                                />
                              </div>
                            )
                          }

                          if(data.name==='TIKTOK'){
                            if(item.input_type=='select'){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <ConnectPlatformSelectInput
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    name={item.name}
                                    inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                    onDataChange={(ev) =>{
                                      handleInputChange( item.name , item.name, ev.target.value )
                                    }}
                                    options={[{label:'US', value:'US'},{label:'OTHERS',value:"OTHERS"}]}
                                  />
                                </div>
                              )
                            }
                          }

                          if(data.name==='AIMS360'){
                            if(item.input_type=='select'){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <ConnectPlatformSelectInput
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    name={item.name}
                                    inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                    onDataChange={(ev) =>{
                                      handleInputChange( item.id , item.name, ev.target.value )
                                    }}
                                    options={[{label:'West US', value:'apiwest'},{label:'East US',value:"apieast" }]}
                                  />
                                </div>
                              )
                            }
                          }

                          if(data.name==='FRUUGO'){
                            if(item.input_type=='select'){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <ConnectPlatformSelectInput
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    name={item.name}
                                    inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                    onDataChange={(ev) =>{
                                      handleInputChange( item.id , item.name, ev.target.value )
                                    }}
                                    options={[{label:'GBP', value:'GBP'},{label:'INR',value:"INR" },{label:"USD",value:"USD"}]}
                                  />
                                </div>
                              )
                            }
                          }

                          if(data.name=='WOOCOMMERCE'){
                            if(item.name=="identifier"){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <InputFieldComponent
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    placeholder='Enter only domain eg. storename.mystore.com'
                                    name={item.name}
                                    inputValue={inputData[item.id] || ''} // Pass the value from inputData
                                    onDataChange={(inputValue) =>
                                      handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                                    }
                                  />
                                </div>
                              )
                              
                            }
                          }

                          if(data.name==='TEMU'){
                            if(item.input_type=='select'){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <ConnectPlatformSelectInput
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    name={item.name}
                                    inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                    onDataChange={(ev) =>{
                                      handleInputChange( item.name , item.name, ev.target.value )
                                    }}
                                    options={[{label:'US', value:'US'},{label:'EU',value:"EU" }]}
                                  />
                                </div>
                              )
                            }
                          }

                          if(data.name=='AMAZONSELLER'){
                            if(item.input_type=='select'){
                              return(
                                <div key={item.id} className={`mb-3 row justify-content-end`}>
                                  <AuthRequirementSelectInput
                                    display_name={item.display_name}
                                    id={item.id}
                                    input_type={item.input_type}
                                    name={item.name}
                                    inputValue={inputData[item.name] || ''} // Pass the value from inputData
                                    onDataChange={(ev) =>{
                                      handleInputChange( item.id , item.name, ev.target.value )
                                    }}
                                  />
                                </div>
                              )
                            }
                          }

                          // else{
                          //   return(
                          //     <div key={item.id} className={`mb-3 row justify-content-end`}>
                          //       <InputFieldComponent
                          //         display_name={item.display_name}
                          //         id={item.id}
                          //         input_type={item.input_type}
                          //         name={item.name}
                          //         inputValue={inputData[item.id] || ''} // Pass the value from inputData
                          //         onDataChange={(inputValue) =>
                          //           handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                          //         }
                          //       />
                          //     </div>
                          //   )
                          // }
                        }
                      // if(process.env.REACT_APP_ENV === 'staging'){
                      // }else{
                      //   return(
                      //     <div key={item.id} className={`mb-3 row justify-content-end`}>
                      //       <InputFieldComponent
                      //         display_name={item.display_name}
                      //         id={item.id}
                      //         input_type={item.input_type}
                      //         name={item.name}
                      //         inputValue={inputData[item.id] || ''} // Pass the value from inputData
                      //         onDataChange={(inputValue) =>
                      //           handleInputChange(inputValue.id, inputValue.name, inputValue.value)
                      //         }
                      //       />
                      //     </div>
                      //   )
                      // }
                    })
                        
                    ) : null
                    
                    }
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} type='button' className='btn-sm' variant='secondary' onClick={()=>{
              closeModal()
              setInputData({
                friendlyName:'',
                intput_type:''
              })
            }}>
              Close
            </Button>
            {/* { (data?.isOauth === false || isConnectingUsingOauth ===false) ?
              (
                <Button disabled={platformSettingsLoading} type='submit' className='btn btn-success btn-sm' variant='primary' onClick={handleSave}>
                  { platformSettingsLoading ? 'Connecting...' : 'Connect'}
                </Button>
              ) : (
                <button disabled={loading} className='btn btn-success btn-sm' type='submit'  onClick={()=>handleOauth()} >{ loading ? 'Connecting...' : 'Connect'}</button>
              )
            } */}
            <button disabled={loading||platformSettingsLoading} className='btn btn-success btn-sm' type='submit' >{ loading||platformSettingsLoading ? 'Connecting...' : 'Connect'}</button>

          </Modal.Footer>
        </form>
      </Modal>

      
    </>
  )
}

export default ConnectPlatformModal
