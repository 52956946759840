import {AsyncThunkConfig, GetThunkAPI} from '@reduxjs/toolkit/dist/createAsyncThunk'
import axios from 'axios'
import React, {FC, useState} from 'react'
import {accessSessionTokenKeyName, logOutHitCount, refreshSessionTokenKeyName} from 'src/utils/constants'
import { setLoggingData } from './slices/authSlice'
import useAppToast from 'src/hooks/useAppToast'


type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ENDPOINT = string
// type TOOLKIT = any
type PARAMS = Object
// type HEADER_OPTIONS = any

const getTokenSync = () => {
  return localStorage.getItem(accessSessionTokenKeyName)
}

const AxiosClient = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: any,
  params: PARAMS
) => {
  const token = getTokenSync()
  const logOutHitCountForSession = localStorage.getItem(logOutHitCount)
  const { warningToast } = useAppToast()
  // console.log('token', token)

  const headerOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (getTokenSync()) {
    headerOptions['Authorization'] = `Bearer ${token}`
  }

  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      params: {...params},
      headers: {...headerOptions},
    })
    return toolkit.fulfillWithValue(response.data)
  } catch (error:any) {
    console.log('error: axios client', error)

    //added this coz if token expire remove all tokens and local storage data
    if(error?.response?.status===401 && token && logOutHitCountForSession===null ){
      localStorage.removeItem(accessSessionTokenKeyName)
      localStorage.removeItem(refreshSessionTokenKeyName)
      localStorage.clear()
      toolkit.dispatch(setLoggingData({isLoggedIn: false, userData: null}))
      localStorage.setItem(logOutHitCount,'ran')
      warningToast('Session is expired. Please login again')
    }
    if(error?.code==="ERR_NETWORK" && endPoint=='/api/authenticated-user' ){
      warningToast(error?.message)
    }
    return toolkit.rejectWithValue(error.response)
  }
}

const publicAxiosClient = async (method: REQUEST_METHODS, endPoint: ENDPOINT, payload: any,  toolkit: any,
  params: PARAMS) => {

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      headers,
      params,
    })
    return toolkit.fulfillWithValue(response.data)
    // return response.data // Return raw data
  }   catch (error:any) {
    // Extract only necessary serializable parts from the error response
    const serializableError = {
      status: error.response?.status,
      data: error.response?.data,
    };

    console.log("error in rrsponse:",serializableError)
    return toolkit.rejectWithValue(serializableError);
  }
}

export {publicAxiosClient, AxiosClient}
export default AxiosClient
