import React, {useEffect, useState} from 'react'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {USER_DATA} from '../_interfaces/auth.interface'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen, faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import {updateusernameAsync} from 'src/redux/actions/handelusernamechange'
import {getNotificationsSettingsAsync, updateNotificationsSettingsAsync, updatepasswordAsync} from 'src/redux/actions/passwordAction'
import useAppToast from 'src/hooks/useAppToast'
import {Formik, Field, ErrorMessage} from 'formik'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PasswordChangeType} from '../_interfaces/passwordChange.interface'
import {Button, Fade, Spinner, Tab, Tabs} from 'react-bootstrap'
import {cancelSubscriptionAsync, getUserPlanAsync} from 'src/redux/actions/pricingActions'
import {UserSubscriptionPlan} from 'src/interfaces/Platform.interface'
import {format} from 'date-fns'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import BillingPlanDetail from './BillingPlanDetail'
import InfoTooltip from './listing/components/InfoTooltip'

const initialValues = {
  previous_password: '',
  new_password: '',
  confirm_password: '',
}

const AccountSetting = () => {
  const dispatch = useAppDispatch()
  const {successToast, errorToast, warningToast} = useAppToast()
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleShownamemodal = () => setShow1(!show1)
  const [passwordVisible, setPasswordVisible] = useState(false) // State to track password visibility
  const [passwordVisible1, setPasswordVisible1] = useState(false)
  const [passwordVisible2, setPasswordVisible2] = useState(false)
  const [timezone, setTimezone] = useState('')
  const [prevPass, setPrevPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confPass, setConfPass] = useState('')
  const {fetchedUserAccountLoading, fetchedUserAccountData} = useAppSelector(
    (state) => state.userAccountData
  )
  const [isSaving, setIsSaving] = useState(false) // State to track saving process
  const [userSubscribedPlan, setUserSubscriptionPlan] = useState<UserSubscriptionPlan>()
  const [userSubscribedPlanLoader, setUserSubscribedPlanLoader] = useState<boolean>(true)
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [isCancelSubscriptionConfirm, setIsCancelSubscriptionConfirm] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const [isNotifyOther, setIsNotifyOther] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const passwordSchema = Yup.object({
    previous_password: Yup.string().required(' enter your previous password'),
    new_password: Yup.string()
      .min(8)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[a-zA-Z\d@$!%*?&.,]{8,}$/,
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
        'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
      )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], 'Passwords must match')
      .required('Confirm password is required'),
  })

  const [userData, setUserData] = useState<USER_DATA>()
  const [name, setName] = useState<string>(userData?.name || '')
  useEffect(() => {
    // Dispatch the fetch user data action on component mount
    // dispatch(fetchUserAccountDetailsAsync())
    //   .then((response) => {
    //     console.log('User account data:', response.payload)
    //     setUserData(response.payload) // Log the response data
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching user data:', error)
    //   })

    dispatch(getUserPlanAsync()).then((response) => {
      console.log('list of  userplan', response)
      if (response?.payload?.data?.billing_plan) {
        setUserSubscriptionPlan(response.payload?.data)
      }
      setUserSubscribedPlanLoader(false)
    })
  }, [])
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const notificationFormData = useFormik({
    initialValues:{
      isNotifyEmail:false,
      isNotifyStaff:false,
      staff_list: fetchedUserAccountData?.email,
    },
    onSubmit: (values, helpers) => {
      console.log('submited values', values);
      if(fetchedUserAccountData?.id){
        dispatch(updateNotificationsSettingsAsync({user_id:fetchedUserAccountData?.id, payload:{ ...values} }))
        .then((response)=>{
          console.log('response for update notification settings', response);
          if(response.payload?.data?.id){
            successToast(response.payload?.message)
          }
          helpers.setSubmitting(false)
        })
        .catch((error) => {
          console.log('error', error)
          warningToast(error?.message+'')
          helpers.setSubmitting(false)
          
        })
      }
      
    }
  })

  useEffect(() => {
    if (fetchedUserAccountData?.name) {
      console.log('fetchedUserAccountData', fetchedUserAccountData)
      setName(fetchedUserAccountData?.name)
    }
    if(fetchedUserAccountData?.timezone){
      setTimezone(fetchedUserAccountData.timezone)
    }

    if(fetchedUserAccountData?.id){
      dispatch(getNotificationsSettingsAsync({user_id:fetchedUserAccountData.id}))
      .then((res) =>{
        notificationFormData.setValues({
          isNotifyEmail:res.payload.user_settings?.isNotifyEmail || false,
          isNotifyStaff:res.payload.user_settings?.isNotifyStaff || false,
          staff_list: res.payload.user_settings?.staff_list && !res.payload.user_settings?.staff_list.includes(fetchedUserAccountData?.email) ?  (fetchedUserAccountData?.email + ',' + res.payload.user_settings?.staff_list) : res.payload.user_settings?.staff_list
        })        
      })
      .catch((err)=>{
        console.log('error in getting notification settings', err);
      })
    }

  }, [fetchedUserAccountData])

  const sendusername = () => {
    // Check if the name field is not empty
    if (name.trim() !== '') {
      const payload = {
        name,
        timezone
      }
      setIsUpdating(true)
      dispatch(updateusernameAsync(payload)).then((response) => {
        successToast('Changes saved successfully')
        setIsUpdating(false)
      })
      .catch(()=>{
        setIsUpdating(false)
      })
    } else {
      // Show an error toast or handle the case where the name field is empty
      errorToast('Name cannot be empty')
      setIsUpdating(false)
    }
  }

  // if (fetchedUserAccountLoading || !fetchedUserAccountData) {
  //   return <div>Loading...</div>; // Render a loading state if data is being fetched or if data is not available yet
  // }
  const buttonStyle = {
    // padding: '2px 2px 2px 2px',
    fontSize: '12px',
    paddingTop: '2px',
    paddingBottom: '2px',
  }

  const formattedValidDate = userSubscribedPlan?.valid_till
    ? format(new Date(userSubscribedPlan?.valid_till), ' dd-MM-yyyy , hh:mm a')
    : '' // Handle null or undefined values

  const formattedActivateDate = userSubscribedPlan?.activated_on
    ? format(new Date(userSubscribedPlan?.activated_on), ' dd-MM-yyyy , hh:mm a')
    : ''

  const {values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting} = useFormik(
    {
      initialValues,
      validationSchema: passwordSchema,
      onSubmit: (values, action) => {
        setIsSaving(true) // Set isSaving to true when form is being submitted
        console.log('submit', values)
        try {
          dispatch(updatepasswordAsync(values)).then((response) => {
            console.log('response on error password ', response)
            setIsSaving(false)
            if (response?.payload?.status_code === 200) {
              successToast(response?.payload?.massage)
              handleClose()
            } else {
              errorToast(
                'Previous Password is incorrect. Please try again with the actual Password.'
              )
            }
          })
        } catch (e) {
          setIsSaving(false)
          console.log('error', e)
        }
        action.resetForm()
      },
    }
  )


  return (
    <>
      <Tabs
        defaultActiveKey='profileDetail'
        id='fill-tab-example'
        // className="mb-3"
        // bsPrefix='bg-dark mb-0 pb-0'
      >
        <Tab
          eventKey='profileDetail'
          title='Profile Details'
          tabClassName='fs-5 fw-bold'
          className='border-0'
        >
          <div className='row justify-content-center'>
            <div className=''>
              <div className=' shadow rounded border-0 p-5'>
                {/* <div className="card-header bg-white ribbon ribbon-start mb-3" >
            <div className="ribbon-label bg-primary"><h4 className='fw-bolder m-0'>Profile Details</h4></div>
          </div> */}

                {fetchedUserAccountLoading ? (
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{height: '50vh'}}
                  >
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='row mb-6'>
                      <label
                        className='col-lg-4 col-form-label required fw-bold'
                        style={{fontSize: '14px'}}
                      >
                        Full Name
                      </label>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Full Name'
                          name='fName'
                          value={name}
                          onChange={handleChangeName}
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        className='col-lg-4 col-form-label required fw-bold'
                        style={{fontSize: '14px'}}
                      >
                        Email:
                      </label>
                      <div className='col-lg-6'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Email'
                          name='email'
                          disabled
                          value={fetchedUserAccountData?.email!}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label
                        className='col-lg-4 col-form-label required fw-bold'
                        style={{fontSize: '14px'}}
                      >
                        Timezone:
                      </label>
                      <div className='col-lg-6'>
                        <select className='form-select form-select-lg form-select-solid' value={timezone}
                           onChange={(ev)=>setTimezone(ev.target.value)} >
                          <option value=''>Select Timezone</option>
                          <option value="PACIFIC_MIDWAY">PACIFIC MIDWAY ( -11:00 )</option>
                          <option value="AMERICA_ADAK">AMERICA ADAK ( -10:00 )</option>
                          <option value="PACIFIC_HONOLULU">PACIFIC HONOLULU ( -10:00 )</option>
                          <option value="AMERICA_ANCHORAGE">AMERICA ANCHORAGE ( -09:00 )</option>
                          <option value="AMERICA_LOS_ANGELES">AMERICA LOS ANGELES ( -08:00 )</option>
                          <option value="AMERICA_DENVER">AMERICA DENVER ( -07:00 )</option>
                          <option value="AMERICA_CHICAGO">AMERICA CHICAGO ( -06:00 )</option>
                          <option value="AMERICA_NEW_YORK">AMERICA NEW YORK ( -05:00 )</option>
                          <option value="AMERICA_CARACAS">AMERICA CARACAS ( -04:00 )</option>
                          <option value="AMERICA_SAO_PAULO">AMERICA SAO PAULO ( -03:00 )</option>
                          <option value="ATLANTIC_SOUTH_GEORGIA">ATLANTIC SOUTH GEORGIA ( -02:00 )</option>
                          <option value="ATLANTIC_AZORES">ATLANTIC AZORES ( -01:00 )</option>
                          <option value="EUROPE_LONDON">EUROPE LONDON ( +00:00 )</option>
                          <option value="EUROPE_BERLIN">EUROPE BERLIN ( +01:00 )</option>
                          <option value="EUROPE_ATHENS">EUROPE ATHENS ( +02:00 )</option>
                          <option value="EUROPE_MOSCOW">EUROPE MOSCOW ( +03:00 )</option>
                          <option value="ASIA_DUBAI">ASIA DUBAI ( +04:00 )</option>
                          <option value="ASIA_KARACHI">ASIA KARACHI ( +05:00 )</option>
                          <option value="ASIA_DHAKA">ASIA DHAKA ( +06:00 )</option>
                          <option value="ASIA_JAKARTA">ASIA JAKARTA ( +07:00 )</option>
                          <option value="ASIA_SHANGHAI">ASIA SHANGHAI ( +08:00 )</option>
                          <option value="ASIA_TOKYO">ASIA TOKYO ( +09:00 )</option>
                          <option value="AUSTRALIA_SYDNEY">AUSTRALIA SYDNEY ( +10:00 )</option>
                          <option value="PACIFIC_NOUMEA">PACIFIC NOUMEA ( +11:00 )</option>
                          <option value="PACIFIC_FIJI">PACIFIC FIJI ( +12:00 )</option>
                        </select>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold' style={{fontSize: '14px'}}>
                        <span className='required'>Password:</span>
                      </label>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <input
                          type='text'
                          disabled
                          className='form-control form-control-lg form-control-solid mb-0 flex-grow-1'
                          placeholder='Password'
                          name='password'
                          value={'**********'}
                          readOnly
                        />
                        <button
                          className='btn btn-sm btn-primary  ms-3'
                          style={{whiteSpace: 'nowrap'}}
                          onClick={handleShow}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                    {/* <div className='separator mt-3 '></div> */}
                    <div className='d-flex justify-content-end py-3 px-2'>
                      <button
                        onClick={sendusername}
                        type='submit'
                        className='btn btn-sm btn-primary fw-bold me-2'
                      >
                        { isUpdating ? 'Saving...' : 'Save Changes'}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey='emailNotifications'
          title='Notification Settings'
          tabClassName='fs-5 fw-bold'
          className='border-0'
        >
          <div className='row justify-content-center'>
            <form onSubmit={notificationFormData.handleSubmit} >
              <div className=' shadow rounded border-0 h-100 p-5'>
                <div className='row mb-6'>
                    <div className="col-5">
                      <label className='form-label fs-5 fw-bold' htmlFor='isNotifyEmail'>
                        Get Email Notifications on Errors <InfoTooltip message='Enable notification for order, shipment and inventory related' />
                      </label>
                    </div>
                    <div className="col-7">
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input w-50px'
                          type='checkbox'
                          id='isNotifyEmail'
                          name='isNotifyEmail'
                          checked={notificationFormData.values.isNotifyEmail}
                          onChange={notificationFormData.handleChange}
                          onBlur={notificationFormData.handleBlur}
                        />
                      </div>
                    </div>
                </div>
                <div className='row mb-6'>
                    <div className="col-5">
                      <label className='form-label fs-5 fw-bold' htmlFor='isNotifyStaff'>
                        Notify Other Users 
                      </label>
                    </div>
                    <div className="col-7">
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input w-50px'
                          type='checkbox'
                          checked={notificationFormData.values.isNotifyStaff}
                          id='isNotifyStaff'
                          name='isNotifyStaff'
                          onChange={notificationFormData.handleChange}
                          onBlur={notificationFormData.handleBlur}
                        />
                      </div>
                    </div>
                </div>
                { notificationFormData.values?.isNotifyStaff ?
                    <div className='row mb-6'>
                      <div className="col-5">
                        <label className='form-label fs-5 fw-bold' htmlFor='flexSwitchDefault'>
                          Add Staff Email 
                        </label>
                      </div>
                      <div className="col-7">
                          {/* <p className='badge badge-success' >{fetchedUserAccountData?.email}</p> */}
                          <textarea placeholder='Enter emails using comma separated'
                          name='staff_list' 
                          value={notificationFormData.values.staff_list} 
                          onChange={notificationFormData.handleChange}
                          onBlur={notificationFormData.handleBlur} 
                          className='form-control mw-400px min-h-100px' 
                          />
                      </div>
                    </div>
                    : null 
                }
                <div className="d-flex justify-content-end">
                  <button type='submit' disabled={notificationFormData.isSubmitting} className="btn btn-sm btn-primary min-w-100px fw-bold fs-6">{ notificationFormData.isSubmitting ? 'Saving...' : 'Save Changes'}</button>
                </div>
                 
              </div>
            </form>
          </div>
        </Tab>
      </Tabs>

      {/* <div className='row justify-content-center' >
        <div className="">
          {
            userSubscribedPlanLoader ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{height: '50vh'}}
              >
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : userSubscribedPlan ?
          
          ( <div className='shadow border-0'>
              
                <>
                  <div className='row mb-6 p-2 mx-1'>
                    <label
                      className='col-lg-4 col-form-label required fw-bold'
                      style={{fontSize: '14px'}}
                    >
                      Current Plan
                    </label>
                    <div className='col-lg-6'>
                      <span className=' form-control-lg mb-3 mb-lg-0'>
                        {userSubscribedPlan?.billing_plan?.display_name}{' '}
                        {userSubscribedPlan?.billing_plan?.price?.currency_iso == 'USD'
                          ? ' $ ' + userSubscribedPlan?.billing_plan?.price?.value
                          : ' ₹ ' + userSubscribedPlan?.billing_plan?.price?.value}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-6 p-2 mx-1'>
                    <label
                      className='col-lg-4 col-form-label required fw-bold'
                      style={{fontSize: '14px'}}
                    >
                      Subscription Status
                    </label>
                    <div className='col-lg-6'>
                      {userSubscribedPlan?.isActive ? (
                        <span className='badge shadow-sm fs-5 bg-light-success text-success ms-3'>Active</span>
                      ) : (
                        <span className='badge badge-light-danger ms-3'>Deactive</span>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6 p-2 mx-1'>
                    <label
                      className='col-lg-4 col-form-label required fw-bold'
                      style={{fontSize: '14px'}}
                    >
                      Subscribed Date
                    </label>
                    <div className='col-lg-6'>
                      <span className=' form-control-lg mb-3 mb-lg-0'>{formattedActivateDate}</span>
                    </div>
                  </div>
                  { userSubscribedPlan?.billing_plan.billing_frequency!=='FREE_FOREVER' && userSubscribedPlan?.isActive ? (
                    <>
                      
                      <div className='d-flex justify-content-end py-3 px-2'>
                        <button 
                            onClick={() => {
                              setIsCancelSubscriptionConfirm(true)
                            }}
                        type='submit' className='btn btn-sm btn-danger fw-bold me-2'>
                          Cancel Subscription
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
            </div>
            ) : null
          }
        </div>
        </div> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className='input-block'>
              <label
                htmlFor='previous_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                Previous Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.previous_password && errors.previous_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible ? 'text' : 'password'}
                  autoComplete='off'
                  name='previous_password'
                  id='previous_password'
                  placeholder=' Enter Previous Password'
                  value={values.previous_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.previous_password && touched.previous_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.previous_password}
                </p>
              ) : null}
            </div>

            <div className='input-block'>
              <label
                htmlFor='new_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                New Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.new_password && errors.new_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible1 ? 'text' : 'password'}
                  autoComplete='off'
                  name='new_password'
                  id='new_password'
                  placeholder=' Enter New Password'
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible1(!passwordVisible1)}
                >
                  {passwordVisible1 ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.new_password && touched.new_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.new_password}
                </p>
              ) : null}
            </div>

            <div className='input-block'>
              <label
                htmlFor='confirm_password'
                className='input-label col-lg-4 col-form-label required fw-bold'
              >
                Confirm Password
              </label>
              <div className='d-flex'>
                <input
                  className={`form-control ${
                    touched.confirm_password && errors.confirm_password ? 'is-invalid' : ''
                  }`}
                  type={passwordVisible2 ? 'text' : 'password'}
                  autoComplete='off'
                  name='confirm_password'
                  id='confirm_password'
                  placeholder=' Enter Confirm Password'
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setPasswordVisible2(!passwordVisible2)}
                >
                  {passwordVisible2 ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              {errors.confirm_password && touched.confirm_password ? (
                <p className='form-error' style={{color: 'red'}}>
                  {errors.confirm_password}
                </p>
              ) : null}
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <Button variant='secondary' className='me-3' onClick={handleClose}>
                Close
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                {' '}
                {isSaving ? 'Saving' : 'Save'}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <CommonAlertModal
        title={
          fetchedUserAccountData?.source === 'WIX'
            ? `You have to cancel your current plan from Wix admin portal`
            : `Are you sure you want to cancel the Subscribed plan? The Plan will be downgraded to Free Forever plan.`
        }
        subtitle=''
        successDisabled={isCanceling}
        successBtnTitle={isCanceling ? 'Updating...' : 'Yes Confirm'}
        isTermsVisible={false}
        isVisible={isCancelSubscriptionConfirm}
        onCancel={() => {
          setIsCancelSubscriptionConfirm(false)
        }}
        onSuccess={() => {
          setIsCanceling(true)
          dispatch(
            cancelSubscriptionAsync({
              subscription_id: userSubscribedPlan?.subscription_id,
              platform_id: mainStoreData?.id,
            })
          ).then((response) => {
            if (response?.payload?.status_code === 201 && response?.payload?.data) {
              successToast(response?.payload?.message)
              setIsCanceling(false)
              setIsCancelSubscriptionConfirm(false)
              dispatch(getUserPlanAsync()).then((response) => {
                
                setUserSubscriptionPlan(response.payload?.data)
              })
            } else {
              errorToast(response?.payload?.message)
              setIsCanceling(false)
              setIsCancelSubscriptionConfirm(false)
              dispatch(getUserPlanAsync()).then((response) => {
                
                setUserSubscriptionPlan(response.payload?.data)
              })
            }
          })
        }}
      />
    </>
  )
}
export default AccountSetting
