import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useState} from 'react'
import { useNavigate } from 'react-router-dom'

type NEWS = {
  id: number
  type: string
  title: string
  description: string
  created_at: string
  updated_at: string
}

const Article: FC<{item: NEWS}> = ({item}) => {
  const [isReadMoreOn, setIsReadMoreOn] = useState(false)
  const navigate = useNavigate()

  return (
    <div className='card border-0 shadow-sm bg-hover-light-primary' >
      <div className='card-body p-2' >
        <div className='d-flex flex-column '>
          <div className='bg-light-primary rounded p-2 d-flex justify-content-between align-items-center ' >
            <span className='mb-0 text-primary fw-bold' style={{fontSize:'14px'}} >{item.title}</span>
            <small className=' fs-8 ms-2 text-secondary fw-bold w-70px'>
              {moment(new Date(item?.created_at)).format('DD MMM YY')}
            </small>
          </div>
          <div className={clsx( "p-2 " , !isReadMoreOn ? 'text-truncate' : '' )}>
            <small className='fs-6 text-secondary fw-bold'>{item.description}</small>
          </div>
        </div>
        <div className='text-end px-2' >
          <button className='btn btn-sm text-primary fw-bold text-end p-0 mt-0 pt-0' onClick={()=>{
            if(item.description.length> 500){
              // window.open('/article/1', '_blank' )
            }else{
              setIsReadMoreOn(!isReadMoreOn)
            }
          }} >{ isReadMoreOn ? 'Read Less' : 'Read More'}</button>
        </div>
      </div>
    </div>
  )
}

export default Article
